import React from 'react';
import { Menu, X, ChevronRight, Facebook, Twitter, Linkedin, Github } from 'lucide-react';

const EmmaCoinLanding = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const teamMembers = [
    {
      name: "Sarah Johnson",
      role: "CEO & Founder",
      description: "Blockchain expert with 10+ years in fintech"
    },
    {
      name: "Michael Chen",
      role: "CTO",
      description: "Former lead developer at major cryptocurrency exchanges"
    },
    {
      name: "Elena Rodriguez",
      role: "Head of Security",
      description: "Cybersecurity specialist with focus on blockchain"
    }
  ];

  const roadmapItems = [
    {
      year: "2024 Q2",
      title: "Project Launch",
      description: "Initial token release and platform development"
    },
    {
      year: "2024 Q3",
      title: "Market Expansion",
      description: "Partnership with major exchanges and increased adoption"
    },
    {
      year: "2024 Q4",
      title: "Ecosystem Growth",
      description: "Launch of developer tools and community initiatives"
    },
    {
      year: "2025 Q1",
      title: "Global Integration",
      description: "International expansion and enhanced features"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="bg-[#003f88] text-white">
        <nav className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-bold">Emma Coin</div>
            
            {/* Mobile menu button */}
            <div className="md:hidden">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {isMenuOpen ? <X /> : <Menu />}
              </button>
            </div>
            
            {/* Desktop menu */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#about" className="hover:text-[#ffc107]">Sobre</a>
              <a href="#benefits" className="hover:text-[#ffc107]">Benefícios</a>
              <a href="#roadmap" className="hover:text-[#ffc107]">Roadmap</a>
              <a href="#team" className="hover:text-[#ffc107]">Equipe</a>
              <a href="#contact" className="hover:text-[#ffc107]">Contato</a>
              <button className="bg-[#28a745] px-6 py-2 rounded-full hover:shadow-lg transition-all">
                Compre Emma Coins
              </button>
            </div>
          </div>
          
          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden mt-4 space-y-4">
              <a href="#about" className="block hover:text-[#ffc107]">Sobre</a>
              <a href="#benefits" className="block hover:text-[#ffc107]">Benefícios</a>
              <a href="#roadmap" className="block hover:text-[#ffc107]">Roadmap</a>
              <a href="#team" className="block hover:text-[#ffc107]">Equipe</a>
              <a href="#contact" className="block hover:text-[#ffc107]">Contato</a>
              <button className="bg-[#28a745] px-6 py-2 rounded-full w-full">
                Compre Emma Coins
              </button>
            </div>
          )}
        </nav>
      </header>

      {/* Hero Section */}
      <section className="bg-[#003f88] text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h1 className="text-5xl font-bold mb-6">
            Invista no futuro com <span className="text-[#ffc107]">Emma Coin!</span>
          </h1>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Uma moeda digital inovadora construída para revolucionar o mercado financeiro,
            combinando segurança, transparência e alto potencial de valorização.
          </p>
          <button className="bg-[#28a745] px-8 py-3 rounded-full text-lg hover:shadow-lg transition-all inline-flex items-center">
            Saiba Mais <ChevronRight className="ml-2" />
          </button>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-20 bg-[#f8f9fa]">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-12 text-[#003f88]">
            Sobre a Emma Coin
          </h2>
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <img src="/api/placeholder/600/400" alt="Emma Coin Concept" className="rounded-lg shadow-lg" />
            </div>
            <div>
              <p className="text-lg mb-6">
                Emma Coin é mais do que apenas uma criptomoeda - é uma revolução no mundo
                das finanças digitais. Nossa missão é proporcionar uma experiência de
                investimento transparente, segura e altamente rentável.
              </p>
              <p className="text-lg">
                Com tecnologia blockchain de última geração e uma equipe de especialistas
                comprometidos, estamos construindo o futuro das transações digitais.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section id="benefits" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-12 text-[#003f88]">
            Benefícios
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {['Transparência e Segurança', 'Alta Rentabilidade', 'Blockchain Própria'].map((benefit, index) => (
              <div key={index} className="p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="w-16 h-16 bg-[#ffc107] rounded-full mb-6 flex items-center justify-center">
                  <img src="/api/placeholder/32/32" alt={benefit} />
                </div>
                <h3 className="text-xl font-bold mb-4">{benefit}</h3>
                <p className="text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Roadmap Section */}
      <section id="roadmap" className="py-20 bg-[#f8f9fa]">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-12 text-[#003f88]">
            Roadmap
          </h2>
          <div className="space-y-8">
            {roadmapItems.map((item, index) => (
              <div key={index} className="flex flex-col md:flex-row items-start md:items-center gap-4 p-6 bg-white rounded-lg shadow-lg">
                <div className="text-[#ffc107] font-bold text-xl w-32">{item.year}</div>
                <div>
                  <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section id="team" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-12 text-[#003f88]">
            Nossa Equipe
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
              <div key={index} className="text-center">
                <img src="/api/placeholder/200/200" alt={member.name} className="w-48 h-48 rounded-full mx-auto mb-4" />
                <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                <p className="text-[#ffc107] font-medium mb-2">{member.role}</p>
                <p className="text-gray-600">{member.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section id="contact" className="py-20 bg-[#003f88] text-white">
        <div className="container mx-auto px-6">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-8">
              Comece sua jornada com Emma Coin
            </h2>
            <form className="space-y-4">
              <input
                type="text"
                placeholder="Seu nome"
                className="w-full px-4 py-3 rounded-lg text-gray-900"
              />
              <input
                type="email"
                placeholder="Seu e-mail"
                className="w-full px-4 py-3 rounded-lg text-gray-900"
              />
              <button className="bg-[#28a745] px-8 py-3 rounded-full text-lg hover:shadow-lg transition-all w-full">
                Receber Informações
              </button>
            </form>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-[#343a40] text-white py-12">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Emma Coin</h3>
              <p className="text-gray-400">
                Transformando o futuro das finanças digitais
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Links Rápidos</h3>
              <ul className="space-y-2">
                <li><a href="#about" className="text-gray-400 hover:text-white">Sobre</a></li>
                <li><a href="#benefits" className="text-gray-400 hover:text-white">Benefícios</a></li>
                <li><a href="#roadmap" className="text-gray-400 hover:text-white">Roadmap</a></li>
                <li><a href="#team" className="text-gray-400 hover:text-white">Equipe</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Contato</h3>
              <p className="text-gray-400">contato@emmacoin.com</p>
              <p className="text-gray-400">+55 11 9999-9999</p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Redes Sociais</h3>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white">
                  <Facebook />
                </a>
                <a href="#" className="text-gray-400 hover:text-white">
                  <Twitter />
                </a>
                <a href="#" className="text-gray-400 hover:text-white">
                  <Linkedin />
                </a>
                <a href="#" className="text-gray-400 hover:text-white">
                  <Github />
                </a>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
            <p>&copy; 2024 Emma Coin. Todos os direitos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EmmaCoinLanding;
